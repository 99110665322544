import React, { useRef } from "react";

import Layout from '../components/layout';
import SEO from '../components/seo';

//components
import Carousel from '../components/carousel';
import SchoolHeader from '../components/school/schoolheader';
import SchoolCard from '../components/school/schoolcard';
import Track from '../components/track';
import ImageBackground from '../components/imagebackground';
import settings from '../../settings';
import HighlightItem from "../components/HighlightItem";
import BmwDriverSchoolGlance from "../components/bmwGlance/bmwDriverSchoolGlance";

//images

const onedaydriver = `${settings.IMAGES_BASE_URL}/images/driverschool/one-day-driver.jpg`;
const twodaydriver = `${settings.IMAGES_BASE_URL}/images/driverschool/two-day-driver.jpg`;
const interstatebraking = `${settings.IMAGES_BASE_URL}/images/driverschool/interstate-braking.jpg`;
const doublelanechange = `${settings.IMAGES_BASE_URL}/images/driverschool/double-lane-change.jpg`;
const panicbraking = `${settings.IMAGES_BASE_URL}/images/driverschool/panic-braking.jpg`;
const handlingcourse = `${settings.IMAGES_BASE_URL}/images/driverschool/handling-course.jpg`;
const wetskidpad = `${settings.IMAGES_BASE_URL}/images/driverschool/wet-skid-pad.jpg`;
const timeChallenge = `${settings.IMAGES_BASE_URL}/images/driverschool/time-challenge.jpg`;

const interstatebrakingMobile = `${settings.IMAGES_BASE_URL}/images/driverschool/interstate-braking.jpg`;
const doublelanechangeMobile = `${settings.IMAGES_BASE_URL}/images/driverschool/double-lane-change.jpg`;
const panicbrakingMobile = `${settings.IMAGES_BASE_URL}/images/driverschool/panic-braking.jpg`;
const handlingcourseMobile = `${settings.IMAGES_BASE_URL}/images/driverschool/handling-course.jpg`;
const wetskidpadMobile = `${settings.IMAGES_BASE_URL}/images/driverschool/wet-skid-pad.jpg`;
const timeChallengeMobile = `${settings.IMAGES_BASE_URL}/images/driverschool/time-challenge.jpg`;

const topbannerDesktop = `${settings.IMAGES_BASE_URL}/images/driverschool/bmw-drivers-school.jpg`;
const topbannerMobile = `${settings.IMAGES_BASE_URL}/images/driverschool/bmw-drivers-school-mobile.jpg`;

const slideone = `${settings.IMAGES_BASE_URL}/images/driverschool/driver-slide1.jpg`;
const slidetwo = `${settings.IMAGES_BASE_URL}/images/driverschool/driver-slide2.jpg`;
const slidethree = `${settings.IMAGES_BASE_URL}/images/driverschool/driver-slide3.jpg`;

const carouselimages = [
  { id: 1, image: `${slideone}`, alt: 'Bottom Banner Drivers 01' },
  { id: 2, image: `${slidetwo}`, alt: 'Bottom Banner 3 Series 02' },
  { id: 3, image: `${slidethree}`, alt: 'Bottom Banners BMW 3 Series 03' }
];

const DriverSchoolPage = () => {
  const contentRef = useRef(null)

  const scrollToContent= ()=>{
    contentRef.current.scrollIntoView({ behavior: "smooth" });
  }

  const today = new Date();
  return (
  <Layout>
    <SEO title="BMW Driver's School | BMW Performance Driving School" 
      description="Unlock your driving potential with BMW Driver’s School. 
      This performance driving class puts you behind the wheel of a powerful BMW and pushes you to new 
      limits on track." 
      keywords="bmw performance driving school, bmw driving school, performance driving lessons, bmw school, advanced driving school"
    />

    <div className="driverschool-page">
      <ImageBackground
        imageDesktop={topbannerDesktop}
        imageMobile={topbannerMobile}
        imageAlt="BMW Driver's School"
        hideMobileHeader={true}
        text="GO AHEAD. MAKE YOUR DREAM COME TRUE."
        scrollTo={scrollToContent}
        topLeftChildren={
          <div className="text_left">
            <div>GO AHEAD. MAKE YOUR</div>
            <div>DREAM COME TRUE.</div>
          </div>
        }
      />

      <section className="school" ref={contentRef}>
        <SchoolHeader
          title="BMW DRIVER’S SCHOOL"
          subheader="HIGH-PERFORMANCE DRIVING CLASS"
          subtitle="Discover why the Ultimate Driving Machine isn’t just a tagline. Each BMW was built for maximum enjoyment at the limit, and you’ll be able to experience every spine-tingling sensation behind the wheel of our high-performance driving classes."
        />
        <SchoolCard
          image={onedaydriver}
          imageAlt="BMW One-Day Driver's School"
          header="BMW ONE-DAY DRIVER'S SCHOOL"
          price={ today >= new Date(2022,0,1) ? "$999" : "$849"}
          subheader="ELEVATED EXCITEMENT"
          text="Start with a brief classroom session to go over key concepts, then take it to the track for pulse-elevating exercises involving the wet skid pad and panic braking. After lunch, you’ll head back for the Handling Course, and finally a timed lap to bring together everything learned that day. You won’t need your typical morning coffee."
          monthSliderHeader={"CLICK ON A MONTH BELOW TO VIEW AVAILABLE CLASSES"}
          monthSlider={true}
          noteClassName="customMobile"
          note="Please note the Summer hours for our Performance Center West location only. Classes in Thermal will begin at 6AM, June 1st through September 30th. Please check your confirmation email for details."
        />
        <SchoolCard
          image={twodaydriver}
          imageAlt="BMW X5 Off Road"
          header="BMW Two-DAY DRIVER'S SCHOOL"
          price={ today >= new Date(2022,0,1) ? "$1,999" : "$1,699"}
          subheader="HONE YOUR DRIVING SKILLS"
          monthSliderHeader={"CLICK ON A MONTH BELOW TO VIEW AVAILABLE CLASSES"}
          monthSlider
          monthSliderQuery="&class=BMW_Two_Day_School"
          noteClassName="customMobile"
          text="BMW Two-Day Driver’s School includes everything from One-Day School, plus skill-advancing exercises like the Double Lane Change Course and interstate braking from 70 mph. You’ll also experience other BMWs as you head to the Off-Road Course to pilot X vehicles through adverse conditions. Finally, enjoy a Performance Drive, where you can take three different BMW models around our track, and learn the unique handling characteristics of each. Prepare for two full days of adrenaline."
          note="Please note the Summer hours for our Performance Center West location only. Classes in Thermal will begin at 6AM, June 1st through September 30th. Please check your confirmation email for details."
          />
      </section>

      <section className="coursehiglight">
        <div className="container">
          <div className="coursehighlight__header">
            <h2>Course Highlights</h2>
            <p className="description">All BMW high-performance driving classes offer exhilaration behind the wheel of a BMW on our closed courses under the guidance of pro instructors.</p>
          </div>
        </div>
        <div className="container">
          <div className="highlights">
            <HighlightItem
              key="highlight-interstate-braking"
              imageDesktop={interstatebraking}
              imageMobile={interstatebrakingMobile}
              title="Interstate Braking"
              text="Students start out driving steady a 70 mph in a straight line. In their path is a box made of cones, and students must decide both at what point to hit the brakes and how much brake pressure is needed in order to stop correctly, all while keeping the car under control."
              altImage="interstate-braking"
            />

            <HighlightItem
              key="highlight-double-lane-change"
              imageDesktop={doublelanechange}
              imageMobile={doublelanechangeMobile}
              title="Double Lane Change"
              text="Students will explore how a car handles changes in direction, as their vehicle’s weight shifts through a series of left to right turns. Avoid hitting cones, and you’ll win the day."
              altImage="double-lange-change"
            />

            <HighlightItem
              key="highlight-wet-ski-pad"
              imageDesktop={wetskidpad}
              imageMobile={wetskidpadMobile}
              title="Wet Skid Pad"
              text="A polished concrete skid pad offers up the perfect spot to learn how to handle your car at the limit of grip. You’ll learn to feel when the car starts to slip and how to control it through a drift. Yea, it’s a blast."
              altImage="Wet Skid Pad"
            />

            <HighlightItem
              key="highlight-panic-braking"
              imageDesktop={panicbraking}
              imageMobile={panicbrakingMobile}
              title="Panic Braking"
              text="Learn to brake hard from speeds up to 55 mph while navigating a corner. Sounds easy: it isn’t."
              altImage="panic-braking"
            />

            <HighlightItem
              key="highlight-handling-course"
              imageDesktop={handlingcourse}
              imageMobile={handlingcourseMobile}
              title="Handling Course"
              text="Students will get an overall feel for their BMWs as they drive through our handling course. Developing a feel for the limit of traction while accelerating, braking and cornering, it’s an exercise aimed at overall car control."
              altImage="handling-course"
            />

            <HighlightItem
              key="highlight-time-challenge"
              imageDesktop={timeChallenge}
              imageMobile={timeChallengeMobile}
              title="Time Challenge"
              text="Let your competitive side show as you combine all the skills learned into one cohesive lap. Can you lay down the best time in your group and win bragging rights?"
              altImage="Time Challenge"
            />
          </div>
        </div>
      </section>

      <Track variant="white" titleClassName="track__header_bold" />

      <div className="bottom-section">
        <Carousel images={carouselimages} />
      </div>
      <BmwDriverSchoolGlance/>
    </div>
  </Layout>
)
}

export default DriverSchoolPage;
